import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <h2>Contact</h2>
        </div>
      </div>
    );
  }
}

export default About
