import React, { useState, useEffect } from 'react';

function Slideshow() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [projects, setProjects] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    fetch(apiUrl+ 'projects')
      .then(response => response.json())
      .then(data => setProjects(data))
      .catch(error => console.error('Errore durante la chiamata all\'API:', error));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [projects]);

  if (!projects) {
    return <div>Loading..</div>;
  }

  return (
    <div class="slideshow-container col-100 vh-100 margin-center fixed top-0 right-0 z-1">
        {projects.map((project, index) => (
          <div
              key={index}
              className={index === currentIndex ? 'slide active' : 'slide'}
              style={{
                backgroundImage: `url(./images/projects/${project.title}.jpg)`
              }}
            >

            <div class="overlay"></div>

            <div class="absolute bottom-0 left-0 content padding-100 sm-padding-bottom-100">
              <p class="light padding-10 uppercase s-font-size">Appartamenti in vendita</p>
              <p class="slideshow-title uppercase light l-font-size">Residenza {project.title}</p>
              <p class="slideshow-subtitle bold">{project.location}</p>
            </div>
          </div>
        ))}

    </div>
  );
}

export default Slideshow;
