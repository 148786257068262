import React from 'react';
import { Route } from 'react-router-dom';

import Home from '../pages/Home';
import Projects from '../pages/Projects';
import Contact from '../pages/Contact';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/projects',
    component: Projects,
    exact: false
  },
  {
    path: '/contact',
    component: Contact,
    exact: false
  }
];

export default routes;
