import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

class Footer extends Component {
  render() {
    const email = `mailto:${process.env.REACT_APP_EMAIL}`;
    const phone = `mailto:${process.env.REACT_APP_PHONE}`;
    const instagram = process.env.REACT_APP_INSTAGRAM;

    return (
      <footer>
          <ul>
            <li class="col-33 inline-block">
              <a href={email}>
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </li>

            <li class="col-33 inline-block">
              <a href={phone}>
                <FontAwesomeIcon icon={faPhone} />
              </a>
            </li>

            <li class="col-33 inline-block">
              <a href={instagram} target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
          </ul>
      </footer>
    );
  }
}

export default Footer;
