import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer.js';

class Header extends Component {
  render() {
    
    return (
      <header class="bg-dark-soft">
      
        <Link to="/">
          <img src="images/logo.png" id="logo"/>
        </Link>
        
        <p class="s-font-size margin-top-25 l-lh">{process.env.REACT_APP_ABOUT}</p>
        
        <Footer />

      </header>
    );
  }
}

export default Header;
