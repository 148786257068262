import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

class Projects extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(apiUrl + 'projects');
      
      const jsonData = await response.json();
      this.setState({ data: jsonData });
    } catch (error) {
      this.setState({ error: 'Error fetching data' });
    }
  };

  render() {
    const { data, error } = this.state;

    return (
      <ul class="col-75 margin-center">
        {data &&  data.map(project => (
          <li key={project.uid} class="col-50 inline-block va-middle padding-15">
            <img src={`images/projects/${project.title}.jpg`} class="col-100" />
            <p>{project.title}</p>
            <br/>
            <p>{project.location}</p>
          </li>
        ))}
      </ul> 
    );
  }

}

export default Projects
