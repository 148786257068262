import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RoutesConfig from './utils/RoutesConfig';

import './assets/css/main.css';

import Header from './components/Header.js';
import Loader from './components/Loader.js';

class App extends Component {
  state = {
    isLoaded: false
  };
  
  componentDidMount() {
    this.setState({ isLoaded: true });
  }

  render() {

    const { isLoaded } = this.state;

    if (!isLoaded) {
      return <Loader/>;
    }

    return (
      <Router>
        <div>
          <Header />

          {RoutesConfig.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </div>
      </Router>
    );
  }
}

export default App;
