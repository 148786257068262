import React, { Component, useState, useEffect } from 'react';
import Slideshow from '../components/Slideshow'; // Importa il componente Slideshow

class Home extends Component {  
  
  render() {

    return (
      <div class="relative col-100 vh-100 table">
        
        <Slideshow />

      </div>
    
    );
  }
}

export default Home
